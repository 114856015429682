<template>
  <div class="sound-permission">
    <i class="fas fa-headphones-alt headphones-layout" />
    <p>
      このコンテンツは音が流れます。<br>
      音量にご注意ください。
    </p>
    <button
      class="sound-permission-button"
      @click="preLoadSounds"
    >
      OK
    </button>
  </div>
</template>

<script>
export default {
  name: 'SoundPermission',
  props: {
    targetUrl: { // 移動先URL
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bgmVolume: 1
    }
  },
  beforeCreate() {
  },
  methods: {
    // /****** エントランス動画とチャイム音の事前読み込み ******/
    preLoadSounds() {
      // 動画とサウンドの事前ロード
      const bgmSound = document.getElementById('bgmSound');
      bgmSound.volume = this.bgmVolume;
      bgmSound.load();
      // 動画とサウンドの使用を許可する
      this.$emit('sound-permission');
    }
  }
}
</script>

<style scoped>
.sound-permission {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 1em;
  background:#fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.sound-permission-button {
  background-color: #F18D5F;
  border: none;
  color: #ffffff;
  padding: 4px 8px;
  margin: 1.5em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 120px;
  height: 45px;
  border-radius: 10px;
  font-weight: 900;
}
.headphones-layout {
  margin: 20px;
  font-size: 5em;
  color: #F18D5F;
}
</style>
